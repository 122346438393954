<template>
  <el-dialog
    class="getRolePageBox"
    title="授权系统和角色"
    :visible.sync="authorityManagementVisible"
    width="40%"
    :close-on-click-modal="false"
  >
    <!-- 数据框 -->
    <div class="dataFrame">
      <!-- 为选好的的 -->
      <div class="leftBox">
        <p class="title">
          全部角色
        </p>
        <el-checkbox-group v-model="checkRoleList" @change="getCheckRoleList">
          <div v-for="(item, index) in allRole" :key="index" class="itemCheckbox">
            <h3>{{ item.name }}</h3>
            <el-checkbox v-for="(val, inde) in item.roleNames" :key="inde" :label="val.roleCode">
              {{ val.roleName }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
        <p v-if="allRole.length === 0" class="noDataBox">
          暂无角色
        </p>
      </div>
      <!-- 已选好的 -->
      <div class="rightBox">
        <p class="title">
          已选角色
        </p>
        <div class="checkRole">
          <!-- 已选择的角色 -->
          <div v-for="(item, index) in selectedList" :key="index" class="selectedBox">
            <h3>{{ item.name }}</h3>
            <p v-for="(val, num) in item.arr" :key="num">
              {{ val.roleName }} <i
                class="el-icon-close"
                @click="deleteRole(val.roleCode)"
              />
            </p>
          </div>
        </div>
        <p v-if="checkRoleList.length === 0" class="noDataBox">
          请勾选左侧复选
        </p>
      </div>
    </div>
    <div slot="footer" class="dialog-footer buttonCombination">
      <el-button class="widen-button" size="small" plain @click="authorityManagementVisible = false">
        取消
      </el-button>
      <el-button class="widen-button" size="small" type="primary" @click="submitCheckRole">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { baseinfoSystemAndRole } from '@/http/custApi/institutionalFramework'
export default {
  props: {
    editCheckRoleList: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      authorityManagementVisible: false,
      value: '',
      selectedList: [],
      checkRoleList: [],
      checkAllRole: [],
      allRole: []
    }
  },
  watch: {
    // 获取编辑的时候带过来的
    editCheckRoleList: {
      handler(newVal) {
        this.checkRoleList = []
        if (newVal.length === 0) {
          this.getCheckRoleList()
          return
        }
        newVal.forEach(item => {
          if (item.constructor === Object) {
            this.checkRoleList.push(item.roleCode)
          } else {
            this.checkRoleList.push(item)
          }
        })
        this.getCheckRoleList()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    baseinfoSystemAndRole(res => {
      this.allRole = [...res.data]
      const sycArr = this.$store.getters.getDictionaryItem('SYS_TYPE') || []
      this.allRole.forEach(item => {
        sycArr.forEach(val => {
          if (val.dictId === item.cmpRole) {
            item.name = val.dictName
            item.usePlatform = val.dictId
          }
        })
      })
    })
  },
  methods: {
    submitCheckRole() {
      const arr = []
      this.selectedList.forEach(item => {
        item.arr.forEach(val => {
          arr.push({ roleCode: val.roleCode, usePlatform: item.usePlatform })
        })
      })
      if (arr.length === 0) {
        this.$message.error('请选择')
        return
      }
      this.$emit('getCheckRole', arr)
      this.authorityManagementVisible = false
    },
    // 删除角色
    deleteRole(label) {
      // 从已选的删除掉
      this.checkRoleList.forEach((item, index) => {
        if (item === label) {
          this.checkRoleList.splice(index, 1)
        }
      })
      // 重新获取一下有哪些
      this.getCheckRoleList()
    },
    // 获取权限之类的
    getCheckRoleList() {
      this.selectedList = []
      this.allRole.forEach(item => {
        const arr = []
        item.roleNames.forEach(val => {
          if (this.checkRoleList.includes(val.roleCode)) arr.push(val)
        })
        // 当大于0的时候才添加
        if (arr.length > 0) {
          this.selectedList.push({
            name: item.name,
            usePlatform: item.usePlatform,
            arr: [...arr]
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.getRolePageBox {
  .noDataBox{
    height: 32px;
    line-height: 32px;
  }
  .buttonCombination {
    margin-top: 18px;
    text-align: center;

  }

  .dataFrame {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    font-size: 12px;
    text-align: center;

    .leftBox {
      width: 45%;
      border: 1px solid #f2f2f2;

      .itemCheckbox {
        width: 100%;
        text-align: left;

        h3 {
          font-size: 13px;
          height: 18px;
          margin: 0;
          text-indent: 16px;
          padding: 12px 0;
        }
      }

      /deep/.el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .el-checkbox {
          width: 50%;
          margin: 12px 0;
          text-align: left;

          .el-checkbox__input {
            margin-left: 12px;
          }
        }
      }

      .title {
        font-size: 14px;
        border-bottom: 1px solid #f2f2f2;
        height: 42px;
        line-height: 42px;
        margin: 0;
      }
    }

    .rightBox {
      width: 45%;
      border: 1px solid #f2f2f2;

      .checkRole {
        .selectedBox {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          text-align: left;

          >p {
            width: 50%;
            text-indent: 12px;
            margin: 12px 0;

            i {
              font-size: 13px;
              cursor: pointer;
              // margin-left: 4px;
            }
          }

          h3 {
            width: 100%;
            font-size: 13px;
            height: 18px;
            margin: 0;
            text-indent: 16px;
            padding: 12px 0;
          }
        }
      }

      .title {
        font-size: 14px;
        border-bottom: 1px solid #f2f2f2;
        height: 42px;
        line-height: 42px;
        margin: 0;
      }
    }
  }

  // .authoringSystem {
  //   display: flex;
  //   align-items: center;

  //   >span {
  //     width: 92px;
  //   }

  //   .el-input {
  //     flex: 1;
  //   }

  // }
}
</style>
